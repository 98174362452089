const services = {
  name: "services",
  title: "محصولات",
  children: {
    right: [
      {
        name: "vpc",
        title: "دیتاسنتر ابری",
        route: "/vpc",
        description: "تمام امکانات یک دیتاسنتر فیزیکی در اختیار شما",
        icon: "vpc.svg",
      },
      {
        name: "vps",
        title: "سرور ابری",
        route: "/vps",
        description: "سرور ابری اختصاصی خود را انتخاب کنید",
        icon: "vps.svg",
      },

      {
        name: "paas",
        title: "پلتفرم ابری",
        route: "/paas",
        description: "کانتینرها در خدمت شما برای تمرکز بیشتر",
        icon: "paas.svg",
      },

      {
        name: "ddos",
        title: "کلود گارد",
        route: "/cloudguard",
        description: "چتر حفاظتی کامل و قدرتمند بر فراز سرویس‌های شما",
        icon: "ddos.svg",
      },

      {
        name: "cdn",
        title: "شبکه توزیع محتوا",
        route: "/cloudguard/cdn",
        description: "از هرکجا، به سرعت در دسترس باشید",
        icon: "cdn.svg",
      },

      {
        name: "cloudSecurity",
        title: "امنیت ابری",
        route: "/cloudguard/cloud-security",
        description: "تامین امنیت سرویس‌های ابری با قدرت و سرعت بالا",
        icon: "security.svg",
      },

            {
                name: "manageServices",
                title: "سرویس مدیریت شده",
                route: "/manageservices",
                description: "متخصصین زس را همراه خود داشته باشید",
                icon: "manage.svg",
            },
        ],
        items: [
            {
                name: "infrastructure",
                title: "زیرساخت ابری",
                icon: "infra.svg",
                children: [
                    {
                        name: "datacenter",
                        title: "دیتاسنتر ابری",
                        route: "/vpc",
                    },
                    {
                        name: "server",
                        title: "سرور ابری",
                        route: "/vps",
                    },
                    {
                        name: "virtual-server",
                        title: "سرور مجازی",
                        route: "/virtual-server",
                    },
                    {
                        name: "container",
                        title: "کانتینر ابری",
                        route: "/paas",
                    },
                    {
                        name: "boom",
                        title: "بوم ابری",
                        description: "(Infrastructure as a code)",
                        route: "",
                        soon: true
                    },
                ]
            },
            {
                name: "storage",
                title: "فضای ابری",
                soon: true,
                icon: "storage.svg",
                children: [
                    {
                        name: "storage-cloud",
                        title: "فضای ذخیره‌سازی ابری",
                        route: "",
                        soon: true
                    },
                    {
                        name: "block-storage",
                        title: "بلاک استوریج",
                        route: "",
                        soon: true
                    },
                ]
            },
            {
                name: "manage-service",
                title: "سرویس‌های مدیریت شده",
                icon: "manage24.svg",
                children: [
                    {
                        name: "organization-services",
                        title: "سرویس‌های سازمانی",
                        route: "/manageservices",
                    },
                    {
                        name: "infrastructure-tools",
                        title: "ابزارهای زیرساختی",
                        route: "/manageservices",
                    },
                    {
                        name: "Hardening",
                        title: "بازطراحی امنیتی (Hardening)",
                        route: "/manageservices",
                    },
                    {
                        name: "remoteworking-services",
                        title: "سرویس‌های دورکاری",
                        route: "/manageservices",
                    },
                ]
            },
            {
                name: "content-distribution",
                title: "توزیع محتوا",
                soon: true,
                icon: "cdn24.svg",
                children: [
                    {
                        name: "cdn",
                        title: "شبکه توزیع محتوا (CDN)",
                        route: "/cloudguard/cdn",
                    },
                    {
                        name: "content-compression",
                        title: "فشرده سازی محتوا",
                        route: "/cloudguard/cdn",
                    },
                    {
                        name: "smart-routing",
                        title: "مسیریابی هوشمند",
                        route: "/cloudguard/cdn",
                    },
                    {
                        name: "traffic-reporting",
                        title: "گزارش‌دهی ترافیک",
                        route: "/cloudguard/cdn",
                    },
                ]
            },
            {
                name: "prepheral-services",
                title: "سرویس‌های جانبی",
                soon: true,
                icon: "addons.svg",
                children: [
                    {
                        name: "dns",
                        title: "DNS ابری",
                        route: "/cloudguard/dns",
                    },
                    {
                        name: "api",
                        title: "API",
                        route: "",
                        soon: true
                    },
                    {
                        name: "CLI",
                        title: "CLI",
                        route: "",
                        soon: true
                    },
                    {
                        name: "SDK",
                        title: "SDK",
                        route: "",
                        soon: true
                    },
                ]
            },
            {
                name: "cloud-network",
                title: "شبکه ابری",
                soon: true,
                icon: "cloud-network.svg",
                children: [
                    {
                        name: "floating-ip",
                        title: "IP شناور",
                        route: "",
                        soon: true
                    },
                    {
                        name: "sdn",
                        title: "شبکه اختصاصی (SDN)",
                        route: "",
                        soon: true
                    },
                    {
                        name: "Load Balancing",
                        title: "توزیع بار",
                        description: "(Load Balancing)",
                        route: "",
                        soon: true
                    },
                ]
            },
            {
                name: "cloudGuard",
                title: "کلود گارد",
                icon: "ddos.svg",
                soon: true,
                children: [
                    {
                        name: "ddos",
                        title: "محافظت DDoS",
                        route: "/cloudguard/cloud-security/#ddos",
                    },
                    {
                        name: "cdn",
                        title: "شبکه توزیع محتوا (CDN)",
                        route: "/cloudguard/cdn",
                    },
                    {
                        name: "firewall",
                        title: "فایروال ابری",
                        route: "/cloudguard/cloud-security/#firewall",
                    },
                    {
                        name: "waf",
                        title: "فایروال وب (WAF)",
                        route: "/cloudguard/cloud-security/#waf",
                    },
                    {
                        name: "dns",
                        title: "DNS ابری",
                        route: "/cloudguard/dns",
                    },
                ]
            },
        ]
    }
}

const solutions = {
    name: "solutions",
    title: "راهکار‌ها",
    children: {
        right: [
            {
                name: "migrate",
                title: "مهاجرت ابری",
                route: "/migrate",
                description: "زیرساخت سنتی خود را به ابرها ببرید",
                icon: "migrate.svg",
            },
            {
                name: "private-cloud",
                title: "ابر اختصاصی",
                tag: "Private Cloud",
                route: "/privatecloud",
                description: "ابری مخصوص سازمان خود داشته باشید",
                icon: "private-cloud.svg",
            },

            {
                name: "combination-cloud",
                title: "ابر ترکیبی",
                tag: "Hybrid Cloud",
                route: "/privatecloud",
                description: "ابر اختصاصی و ابر عمومی را همزمان تجربه کنید",
                icon: "combination-cloud.svg"
            },
            {
                name: "manageServices",
                title: "سرویس مدیریت شده",
                route: "/manageservices",
                description: "متخصصین زس را همراه خود داشته باشید",
                icon: "manage.svg"
            },
            {
                name: "partners",
                title: "شرکای ابری",
                route: "",
                description: "همراهان ابری زس را بشناسید و همراهمان شوید",
                icon: "coprate.svg",
                soon: true
            },

        ],
        items: [
            {
                name: "industrial-solutions",
                title: "راهکار براساس صنعت",
                soon: true,
                classes: "",
                icon: "industry.svg",
                children: [
                    {
                        name: "financial",
                        title: "خدمات مالی و بانکی",
                        route: "",
                        soon: true
                    },
                    // {
                    //     name: "concurrency",
                    //     title: "ارز دیجیتال",
                    //     route: "",
                    //     soon: true
                    // },
                    {
                        name: "insurance",
                        title: "خدمات بیمه",
                        route: "",
                        soon: true
                    },
                    {
                        name: "industrial-holdings",
                        title: "مجموعه‌های صنعتی",
                        route: "",
                        soon: true
                    },

                    {
                        name: "health-services",
                        title: "خدمات درمانی",
                        route: "",
                        soon: true
                    },
                    {
                        name: "artifcial-intelligency",
                        title: "هوش مصنوعی",
                        route: "",
                        soon: true,
                    },
                ]
            },
            {
                name: "special-solutions",
                title: "راهکار‌های ویژه",
                col: 5,
                classes: "",
                icon: "special.svg",
                children: [
                    {
                        name: "private-cloud",
                        title: "ابر اختصاصی",
                        route: "/privatecloud",
                    },
                    {
                        name: "organization-cloud-over-noronta",
                        title: "ابر سازمانی بر بستر شبکه نورونتا",
                        route: "",
                        soon: true
                    },
                    {
                        name: "organization-cloud-over-private-networks",
                        title: "ابر سازمانی بر بستر شبکه‌های اختصاصی (MPLS/ APN/ ...)",
                        route: "",
                        soon: true
                    },
                    {
                        name: "mobile-cloud-datacenter",
                        title: "دیتاسنتر سیار ابری",
                        route: "",
                        soon: true
                    },
                    {
                        name: "floating-datacenter",
                        title: "دیتاسنتر شناور (Floating Datacenter)",
                        route: "",
                        soon: true
                    },
                    {
                        name: "disaster-backup",
                        title: "Disaster Backup",
                        route: "",
                        soon: true
                    },
                    {
                        name: "devops-service",
                        title: "DevOps as a Service",
                        route: "",
                        soon: true
                    },
                ]
            },
            {
                name: "industrial-solutions",
                title: "راهکار براساس اندازه شرکت",
                soon: true,
                icon: "company-size.svg",
                classes: "",
                children: [
                    {
                        name: "big",
                        title: "سازمان های بزرگ",
                        route: "",
                        soon: true
                    },
                    {
                        name: "small-and-big",
                        title: "سازمان‌های کوچک و متوسط",
                        route: "",
                        soon: true
                    },
                    {
                        name: "startups",
                        title: "استارتاپ‌ها",
                        route: "",
                        soon: true
                    },
                    {
                        name: "gov",
                        title: "نهاد‌های ملی و دولتی",
                        route: "",
                        soon: true
                    },
                ]
            },
        ]
    }
}

const organizationServices = {
    name: "organization-solutions",
    title: "راهکارهای سازمانی",
    children: {
        items: [
            {
                name: "organization-cloud",
                title: "ابر سازمانی",
                icon: "organization.svg",
                children: [
                    {
                        name: "public-organization-cloud",
                        title: "ابر عمومی سازمانی",
                        route: "/vpc",
                        // icon: "vpc.svg"
                    },
                    {
                        name: "private-organization-cloud",
                        title: "ابر اختصاصی سازمانی",
                        route: "/privatecloud",
                    },
                    {
                        name: "combination-organization-cloud",
                        title: "ابر ترکیبی سازمانی",
                        route: "/privatecloud",
                    },
                    {
                        name: "container-organization",
                        title: "کانتینر و کوبرنتیز سازمانی",
                        route: "/paas",
                    },
                    {
                        name: "cdn-organization-network",
                        title: "شبکه توزیع محتوای سازمانی",
                        route: "/cloudguard/cdn/#cdn-for-enterprises",
                    },
                ]
            },
            {
                name: "organization-profissional-services",
                title: "خدمات تخصصی سازمانی",
                icon: "tech-services-for-organs.svg",
                children: [
                    {
                        name: "backup-michanism",
                        title: "مکانیزم‌های بکاپ",
                        route: "",
                        soon: true
                    },
                    {
                        name: "devops",
                        title: "خدمات DevOps سازمانی",
                        route: "/manageservices",
                    },
                    {
                        name: "organization-infrastructure-monitoring",
                        title: "نظارت بر زیرساخت‌های سازمانی",
                        route: "/manageservices",
                    },
                    {
                        name: "organizaton-storing",
                        title: "ذخیره‌سازی سازمانی",
                        route: "",
                        soon: true
                    },
                    {
                        name: "organization-remoteworking-package",
                        title: "پکیج دورکاری سازمانی",
                        route: "/manageservices",
                    },
                    {
                        name: "organization-internal-connection-package",
                        title: "پکیج ارتباطات درون سازمانی",
                        route: "/manageservices",
                    },
                ]
            },
            {
                name: "comprehensive-organizational-solutions",
                title: "راهکارهای جامع سازمانی",
                icon: "organ-solutions.svg",
                col: 4,
                children: [
                    {
                        name: "organization-cloud",
                        title: "ابر سازمانی",
                        description: "(IaaS + CDN + DDoS Protection + WAF + Managed Service)",
                        route: "",
                        popup: true
                    },
                    {
                        name: "secure-cloud",
                        title: "ابر ایمن",
                        description: "ابر اختصاصی بر بستر شبکه سازمانی نورونتا",
                        route: "",
                        popup: true
                    },
                    {
                        name: "floating-cloud",
                        title: "ابر شناور",
                        description: "بهره‌گیری از چند دیتاسنتر در سطح کشور",
                        route: "",
                        popup: true
                    },
                    {
                        name: "mobile-cloud",
                        title: "ابر متحرک",
                        description: "دیتاسنتر اختصاصی کانتینری به همراه ابر اختصاصی",
                        route: "",
                        popup: true
                    },
                ]
            },
            {
                name: "organization-documentation",
                title: "مستندات سازمانی",
                icon: "organ-docs.svg",
                children: [
                    {
                        name: "sla",
                        title: "سطوح خدمات (SLA)",
                        route: "https://xaas.ir/blog/sla",
                    },
                    {
                        name: "catalog",
                        title: "دانلود کاتالوگ",
                        route: "https://xaas.ir/blog/wp-content/uploads/2023/08/XaaS-Documents-1.zip",
                    },
                    {
                        name: "request-pre-factor",
                        title: "درخواست صدور پیش فاکتور",
                        route: "",
                        popup: true
                    },
                    {
                        name: "request-organization-proposal",
                        title: "درخواست پروپوزال سازمانی",
                        route: "",
                        popup: true
                    },
                ]
            },
            {
                name: "organization-security",
                title: "امنیت سازمانی",
                icon: "organ-security.svg",
                col: 2,
                children: [
                    {
                        name: "ddos",
                        title: "محافظت DDoS",
                        route: "/cloudguard/#ddos-protection",
                    },
                    {
                        name: "waf",
                        title: "WAF",
                        route: "/cloudguard/#waf",
                    },
                    {
                        name: "antivirus",
                        title: "Anti Virus",
                        route: "",
                        soon: true
                    },
                ]
            },
            {
                name: "digital-evolution",
                title: "تحول دیجیتال",
                icon: "digital-tahavol.svg",
                children: [
                    {
                        name: "cloud-migration",
                        title: "مهاجرت ابری",
                        route: "/migrate",
                    },
                    {
                        name: "professional-counseling",
                        title: "مشاوره تخصصی",
                        route: "",
                        popup: true
                    },
                ]
            },

        ]
    }
}

const cloudStation = {
    name: "cloud-station",
    title: "ایستگاه ابری",
    col: 4,
    children: {
        right: [
            {
                name: "how-to-start",
                title: "چطور شروع کنم؟",
                route: "",
                description: "هر آنچه برای شروع تجربه ابری نیاز است بدانید",
                icon: "how-to-start.svg",
                soon: true
            },
            {
                name: "services-guide",
                title: "راهنمای محصولات",
                route: "https://xaas.ir/blog/help",
                description: "محصولات ابر زس را بیشتر بشناسید",
                icon: "service-help.svg"
            },
            {
                name: "weblog",
                title: "وبلاگ ابر زس",
                route: "https://xaas.ir/blog",
                description: "از ابرها بخوانید",
                icon: "blog.svg"
            },
            {
                name: "faq",
                title: "سوالات متداول",
                route: "https://panel.xaas.ir/faq",
                description: "نقاط ابهام خود را سریع‌تر برطرف کنید",
                icon: "faq.svg"
            },
        ],
        items: [
            {
                name: "notitle1",
                title: "در دنیای ابرها کنجکاوی کنید",
                classes: "",
                icon: "left7.svg",
                children: [
                    {
                        name: "weblog",
                        title: "وبلاگ",
                        route: "https://xaas.ir/blog",
                    },
                    {
                        name: "events",
                        title: "ایونت‌ها و وبینارها",
                        route: "",
                        soon: true
                    },
                ]
            },
            {
                name: "notitle2",
                title: "از متخصصین زس بپرسید",
                classes: "",
                icon: "left15.svg",
                children: [
                    {
                        name: "profissional-conselling",
                        title: "مشاوره تخصصی",
                        route: "",
                        popup: true
                    },
                    {
                        name: "connect-to-sells",
                        title: "ارتباط با واحد فروش",
                        route: "",
                        popup: true
                    },
                    {
                        name: "manage-service",
                        title: "سرویس‌های مدیریت شده",
                        route: "/manageservices",
                    },
                    {
                        name: "devops-as-a-service",
                        title: "دواپس به عنوان سرویس",
                        route: "/manageservices",
                    },
                ]
            },
            {
                name: "notitle3",
                title: "در ابر زس",
                classes: "",
                icon: "in-xaas.svg",
                children: [
                    {
                        name: "how-to-start",
                        title: "چطور شروع کنم؟",
                        route: "",
                        soon: true
                    },
                    {
                        name: "services-guide",
                        title: "راهنمای محصولات",
                        route: "https://xaas.ir/blog/help",
                    },
                    {
                        name: "faq",
                        title: "سوالات متداول",
                        route: "https://panel.xaas.ir/faq",
                    },
                    {
                        name: "customers-stories",
                        title: "داستان مشتریان",
                        route: "",
                        soon: true
                    },
                    {
                        name: "sla",
                        title: "سطوح خدمات (SLA)",
                        route: "https://xaas.ir/blog/sla",
                    },
                    {
                        name: "contact-us",
                        title: "تماس با زس",
                        route: "/contactus",
                    },
                    {
                        name: "about-us",
                        title: "درباره زس",
                        route: "/aboutus",
                    },
                ]
            }
        ]
    }
}

const prices = {
    name: "prices",
    title: "قیمت‌گذاری",
    children: {
        right: [
            {
                name: "how-to-price",
                title: "شیوه قیمت‌گذاری",
                route: "https://xaas.ir/blog/sla",
                description: "هر آنچه درمورد نحوه قیمت‌گذاری باید بدانید",
                icon: "main3.svg"
            },
            {
                name: "frees",
                title: "سرویس‌های رایگان زس",
                route: "",
                description: "سرویس‌های رایگان ابر زس را دقیق‌تر بشناسید",
                icon: "free.svg",
                soon: true
            },
            {
                name: "calc",
                title: "ماشین حساب",
                route: "",
                description: "هزینه سرویس مورد نظر خود را محاسبه کنید",
                icon: "calc.svg",
                soon: true
            },
            {
                name: "sla",
                title: "سطوح خدماتی (SLA)",
                route: "https://xaas.ir/blog/sla",
                description: "از تعهدات ابر زس نسبت به سرویس خود مطلع شوید",
                icon: "sla.svg"
            },
        ],
        items: [
            {
                name: "prices",
                title: "قیمت‌گذاری سرویس‌ها",
                col: 4,
                icon: "prices.svg",
                children: [
                    {
                        name: "datacenter",
                        title: "دیتاسنتر ابری",
                        description: "قیمت دیتاسنترهای ابری بر اساس منابع پردازشی مورد نیاز",
                        route: "/vpc",
                        class: "mt-2"
                    },
                    {
                        name: "vps",
                        title: "سرور ابری",
                        description: "جزئیات قیمت‌گذاری سرورهای ابری",
                        route: "/vps",
                        class: "mt-2"
                    },
                    {
                        name: "paas",
                        title: "پلتفرم ابری",
                        description: "مدل قیمت‌گذاری پلتفرم ابری و جزئیات آن",
                        route: "/paas",
                        class: "mt-2"
                    },
                    {
                        name: "cdn",
                        title: "شبکه توزیع محتوا (CDN)",
                        description: "شیوه قیمت‌گذاری شبکه توزیع محتوا بر اساس فاکتورهای کلیدی",
                        route: "/cloudguard/cdn",
                        class: "mt-2",
                    },
                ]
            },
            {
                name: "prices",
                title: "prices",
                styles: "visibility: hidden",
                children: [
                    {
                        name: "storage",
                        title: "فضای ابری",
                        description: "هزینه استفاده از فضای ابری بر اساس فضای مورد نیاز و سایر شرایط",
                        route: "",
                        class: "mt-2",
                        soon: true,
                    },
                    {
                        name: "private-cloud",
                        title: "ابر اختصاصی",
                        description: "شیوه قیمت‌گذاری پیاده‌سازی ابر اختصاصی",
                        route: "/privatecloud",
                        class: "mt-2"
                    },
                    {
                        name: "combination-cloud",
                        title: "ابر ترکیبی",
                        description: "مدل قیمت‌گذاری پیاده‌سازی ابر ترکیبی",
                        route: "/privatecloud",
                        class: "mt-2"

                    },
                    {
                        name: "support-and-migrate",
                        title: "پشتیبانی و مهاجرت ابری",
                        description: "آگاهی از روش محاسبه هزینه سرویس‌های پشتیبانی و مهاجرت ابری",
                        route: "/migrate",
                        class: "mt-2"

                    },
                    {
                        name: "managed-service",
                        title: "سرویس مدیریت شده",
                        description: "آگاهی از شیوه قیمت‌گذاری خدمات سرویس‌های مدیریت شده",
                        route: "/manageservices",
                        class: "mt-2"

                    }
                ]
            }
        ]
    }
}

export default {
  services,
  solutions,
  organizationServices,
  cloudStation,
  prices,
  // items: [

    //     {
    //         name: "academy",
    //         title: "آکادمی ابری",
    //         url: 'https://xaas.ir/blog'
    //     },
    //     {
    //         name: "support",
    //         title: "پشتیبانی",
    //         url: 'https://dashboard.xaas.ir/ticketing'
    //     },
    //     {
    //         name: "aboutus",
    //         title: "درباره زس",
    //         route: "/aboutus"
    //     },
    //     {
    //         name: "contactUs",
    //         title: "تماس با زس",
    //         route: "/contactus"
    //     },
    // ]
}
